@font-face {
  font-family: 'parklioicons';
  src: url('fonts/parklioicons.eot?ykxl0b');
  src: url('fonts/parklioicons.eot?ykxl0b#iefix') format('embedded-opentype'),
    url('fonts/parklioicons.ttf?ykxl0b') format('truetype'),
    url('fonts/parklioicons.woff?ykxl0b') format('woff'),
    url('fonts/parklioicons.svg?ykxl0b#parklioicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'parklioicons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pa-lock_open:before {
  content: '\e982';
}
.pa-offline:before {
  content: '\e980';
}
.pa-grid:before {
  content: '\e981';
}
.pa-link:before {
  content: '\e97b';
}
.pa-global:before {
  content: '\e97c';
}
.pa-forbidden:before {
  content: '\e97d';
}
.pa-camera-in:before {
  content: '\e97e';
}
.pa-camera-out:before {
  content: '\e97f';
}
.pa-android-add:before {
  content: '\e942';
}
.pa-android-add-circle:before {
  content: '\e943';
}
.pa-android-alert:before {
  content: '\e944';
}
.pa-android-arrow-back:before {
  content: '\e945';
}
.pa-android-arrow-down:before {
  content: '\e946';
}
.pa-android-arrow-forward:before {
  content: '\e947';
}
.pa-android-arrow-up:before {
  content: '\e948';
}
.pa-android-delete:before {
  content: '\e949';
}
.pa-android-notifications:before {
  content: '\e94a';
}
.pa-android-notifications-none:before {
  content: '\e94b';
}
.pa-android-notifications-off:before {
  content: '\e94c';
}
.pa-android-pin:before {
  content: '\e94d';
}
.pa-android-send:before {
  content: '\e94e';
}
.pa-android-sync:before {
  content: '\e94f';
}
.pa-android-warning:before {
  content: '\e950';
}
.pa-arrow-back:before {
  content: '\e951';
}
.pa-arrow-down-c:before {
  content: '\e952';
}
.pa-arrow-forward:before {
  content: '\e953';
}
.pa-arrow-up-c:before {
  content: '\e954';
}
.pa-battery-charging:before {
  content: '\e955';
}
.pa-battery-empty:before {
  content: '\e956';
}
.pa-battery-full:before {
  content: '\e957';
}
.pa-battery-half:before {
  content: '\e958';
}
.pa-battery-low:before {
  content: '\e959';
}
.pa-card:before {
  content: '\e95a';
}
.pa-email:before {
  content: '\e95b';
}
.pa-email-unread:before {
  content: '\e95c';
}
.pa-eye:before {
  content: '\e95d';
}
.pa-eye-disabled:before {
  content: '\e95e';
}
.pa-help-circled:before {
  content: '\e95f';
}
.pa-information-circled:before {
  content: '\e960';
}
.pa-ios-paperplane:before {
  content: '\e961';
}
.pa-lagacy-barrier:before {
  content: '\e962';
}
.pa-load-c:before {
  content: '\e963';
}
.pa-paper-airplane:before {
  content: '\e964';
}
.pa-plus-round:before {
  content: '\e965';
}
.pa-extension:before {
  content: '\e90c';
}
.pa-custom:before {
  content: '\e90d';
}
.pa-been-here:before {
  content: '\e90e';
}
.pa-cog:before {
  content: '\e90f';
}
.pa-x-circle:before {
  content: '\e910';
}
.pa-lock:before {
  content: '\e911';
}
.pa-cloud-download:before {
  content: '\e912';
}
.pa-whatsapp:before {
  content: '\e913';
}
.pa-check-circle:before {
  content: '\e914';
}
.pa-check:before {
  content: '\e915';
}
.pa-chevrons-right:before {
  content: '\e916';
}
.pa-chevron-up:before {
  content: '\e917';
}
.pa-chevron-right:before {
  content: '\e918';
}
.pa-chevron-left:before {
  content: '\e919';
}
.pa-chevron-down:before {
  content: '\e91a';
}
.pa-linkedin:before {
  content: '\e91b';
}
.pa-twitter:before {
  content: '\e91c';
}
.pa-instagram:before {
  content: '\e91d';
}
.pa-facebook:before {
  content: '\e91e';
}
.pa-blogger:before {
  content: '\e91f';
}
.pa-customSolution:before {
  content: '\e900';
}
.pa-API:before {
  content: '\e901';
}
.pa-barrier:before {
  content: '\e902';
}
.pa-brain:before {
  content: '\e903';
}
.pa-chain:before {
  content: '\e904';
}
.pa-detect:before {
  content: '\e905';
}
.pa-dongle:before {
  content: '\e906';
}
.pa-gate:before {
  content: '\e907';
}
.pa-gateway:before {
  content: '\e908';
}
.pa-inavigation:before {
  content: '\e909';
}
.pa-LPR:before {
  content: '\e90a';
}
.pa-bollard:before {
  content: '\e90b';
}
.pa-quality:before {
  content: '\e920';
}
.pa-rocket:before {
  content: '\e921';
}
.pa-share:before {
  content: '\e922';
}
.pa-smartphone:before {
  content: '\e923';
}
.pa-support:before {
  content: '\e924';
}
.pa-user:before {
  content: '\e925';
}
.pa-photo:before {
  content: '\e926';
}
.pa-android:before {
  content: '\e927';
}
.pa-calendar:before {
  content: '\e928';
}
.pa-edit:before {
  content: '\e929';
}
.pa-favoritesFull:before {
  content: '\e92a';
}
.pa-home:before {
  content: '\e92b';
}
.pa-favorites:before {
  content: '\e92c';
}
.pa-iconNo:before {
  content: '\e92d';
}
.pa-iconYes:before {
  content: '\e92e';
}
.pa-info:before {
  content: '\e92f';
}
.pa-location:before {
  content: '\e930';
}
.pa-lock1:before {
  content: '\e931';
}
.pa-logOut:before {
  content: '\e932';
}
.pa-ios:before {
  content: '\e933';
}
.pa-options:before {
  content: '\e934';
}
.pa-photo1:before {
  content: '\e935';
}
.pa-menu:before {
  content: '\e936';
}
.pa-star:before {
  content: '\e937';
}
.pa-search:before {
  content: '\e938';
}
.pa-time:before {
  content: '\e939';
}
.pa-starFull:before {
  content: '\e93a';
}
.pa-medal:before {
  content: '\e93b';
}
.pa-wifi:before {
  content: '\e93c';
}
.pa-bluetooth:before {
  content: '\e93d';
}
.pa-delete:before {
  content: '\e93e';
}
.pa-chevrons-left:before {
  content: '\e93f';
}
.pa-key:before {
  content: '\e940';
}
.pa-list:before {
  content: '\e941';
}
.pa-ion-android-car:before {
  content: '\e966';
}
.pa-truck:before {
  content: '\e967';
}
.pa-chevrons-left1:before {
  content: '\e968';
}
.pa-chat:before {
  content: '\e969';
}
.pa-chat-outline:before {
  content: '\e96a';
}
.pa-door:before {
  content: '\e96b';
}
.pa-garage-door:before {
  content: '\e96c';
}
.pa-off:before {
  content: '\e96d';
}
.pa-powerOff:before {
  content: '\e96e';
}
.pa-powerOn:before {
  content: '\e96f';
}
.pa-android-remove-circle:before {
  content: '\e970';
}
.pa-minus-round:before {
  content: '\e971';
}
.pa-android-remove:before {
  content: '\e972';
}
.pa-play:before {
  content: '\e973';
}
.pa-video:before {
  content: '\e974';
}
.pa-solarPanel:before {
  content: '\e975';
}
.pa-sort:before {
  content: '\e976';
}
.pa-terminal:before {
  content: '\e977';
}
.pa-locator:before {
  content: '\e978';
}
.pa-pms:before {
  content: '\e979';
}
.pa-gsign:before {
  content: '\e97a';
}
.pa-chevrons-left1:before {
  content: '\e968';
}
.pa-chat:before {
  content: '\e969';
}
.pa-chat-outline:before {
  content: '\e96a';
}
.pa-door:before {
  content: '\e96b';
}
.pa-garage-door:before {
  content: '\e96c';
}
.pa-off:before {
  content: '\e96d';
}
.pa-powerOff:before {
  content: '\e96e';
}
.pa-powerOn:before {
  content: '\e96f';
}
.pa-android-remove-circle:before {
  content: '\e970';
}
.pa-minus-round:before {
  content: '\e971';
}
.pa-android-remove:before {
  content: '\e972';
}
.pa-play:before {
  content: '\e973';
}
.pa-video:before {
  content: '\e974';
}
.pa-solarPanel:before {
  content: '\e975';
}
.pa-sort:before {
  content: '\e976';
}
.pa-terminal:before {
  content: '\e977';
}
.pa-locator:before {
  content: '\e978';
}
.pa-pms:before {
  content: '\e979';
}
.pa-gsign:before {
  content: '\e97a';
}
